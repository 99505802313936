<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-tags"></i> Asset Type</b-card-title>
			<b-card-sub-title>Handles the asset type registration</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text" :options="companyOptions"
												:reduce="(company) => company.value" v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Status">
											<v-select class="style-chooser" label="text" :options="statusOptions"
												:reduce="(isActive) => isActive.value" v-model="filterBy.isActive">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row class="mt-4 mb-2">
					<b-col sm="6" md="3">
						<b-dropdown id="asset-type-select-actions" text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item v-b-modal.add-asset-type v-show="!isViewer">
								Add Asset Type
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls"
									:name="fileName + '.xls'">
									Export Asset Types in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv"
									:name="fileName + '.csv'">
									Export Asset Types to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table ref="assetTypesTable" show-empty striped hover :items="items" :fields="fields"
					:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

					<template v-slot:cell(actions)="row">
						<span class="text-nowrap">
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
								@click.stop="row.toggleDetails" class="mr-1">
								<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
								<i class="fa fa-eye" v-else></i>
							</b-button>
							<b-button size="sm" v-b-modal.edit-asset-type v-b-tooltip.hover.top="'Edit Details'"
								variant="warning" @click.stop="updateSelAssetType(row.item)" class="mr-1"
								v-show="!isViewer && isAssetTypeOwner(row.item)">
								<i class="fa fa-pencil"></i>
							</b-button>
						</span>
					</template>

					<template v-slot:cell(status)="row">
						<a @click="updateSelAssetType(row.item)" v-if="!isViewer && isAssetTypeOwner(row.item)">
							<b-form-checkbox name="status" v-b-modal.activate-asset-type v-model="row.item.isActive"
								switch size="sm">
								{{ row.item.isActive === 'true' ? 'Active' : 'Inactive' }}
							</b-form-checkbox>
						</a>
						<span v-else>
							{{ row.item.isActive === 'true' ? 'Active' : 'Inactive' }}
						</span>
					</template>

					<template slot="row-details" slot-scope="row">
						<AssetTypeDetailsView :row="row" :allCompaniesObj="allCompaniesObj" :allUOMsObj="allUOMsObj"/>
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</div>
		</b-card>

		<!-- Modals here -->
		<AddAssetType :companyFilter="filterBy.company" :allCompaniesObj="allCompaniesObj" :allAssetTypesObj="allAssetTypesObj"
			:companyOptions="companyOptions" :uomOptions="uomOptions" />
		<EditAssetType :allCompaniesObj="allCompaniesObj" :allAssetTypesObj="allAssetTypesObj" :uomOptions="uomOptions" />
		<ActivateAssetType />
	</div>
</template>

<script>
// Component
import AssetTypeDetailsView from './assetType/AssetTypeDetailsView';
import AddAssetType from './assetType/AddAssetType';
import ActivateAssetType from './assetType/ActivateAssetType';
import EditAssetType from './assetType/EditAssetType';

// Utils
import { AssetTypeUtil } from '@/utils/assetTypeUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import assetTypeApi from '@/api/assetTypeApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'asset-type',
	components: {
		AssetTypeDetailsView,
		AddAssetType,
		EditAssetType,
		ActivateAssetType,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'name',
					label: 'Name',
					sortable: true,
				},
				{
					key: 'origin',
					label: 'Owner',
					sortable: true,
				},
				{
					key: 'uom',
					label: 'Unit of Measure (UOM)',
					sortable: true,
				},
				'status',
				{
					key: 'actions',
					thClass: 'text-center'
				}
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			companyOptions: [],
			statusOptions: config.statusOptionsWithDefault,
			uomOptions: [],

			allCompaniesObj: {},
			allAssetTypesObj: {},
			allUOMsObj: {},

			selAssetType: {},

			defaultFilterBy: {
				company: { ...config.companyDefaultValue },
				isActive: 'true',
			},
			filterBy: {
				company: { ...config.companyDefaultValue },
				isActive: 'true',
			},
			prevFilter: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			let fields = {
				Name: 'name',
				Owner: 'origin',
				'Unit of Measure (UOM)': 'uom',
				Status: 'status',
				Description: 'description',
				Category: 'type',
				'Unit Cost':'unitCost',
				'Other Inventory Details': 'Other Inventory Details',
			};

			// Rental Details
			fields['Is Rental?'] = 'Is Rental?';
			if (this.isBillingEnabled || this.isSuperAdmin) {
				fields['Rent Frequency'] = 'rentFrequency';
				fields['Rent Rate'] = 'rentRate';
			}

			fields['Status'] = 'status';
			fields['Is Public?'] = 'Is Public?';
			fields['Date Created'] = 'Date Created';
			fields['Date Updated'] = 'Date Updated';
			fields['Created By'] = 'createdBy';
			fields['Updated By'] = 'updatedBy';

			return fields;
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'AssetTypes-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},

		isBillingEnabled() {
			if (this.loggedUserCompany && this.loggedUserCompany.id) {
				const companyObj = this.allCompaniesObj[this.loggedUserCompany.id] ? this.allCompaniesObj[this.loggedUserCompany.id] : {};
				let permissions = companyObj.permissions ? companyObj.permissions : {};
				return permissions.billing ? permissions.billing : false;
			}
			return false;
		}
	},
	mounted() {
		setTimeout(() => {
			try {
				// Filter Access
				if (this.$store.getters.isScanner || this.$store.getters.isRetailer) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// show loading indicator
				this.isLoading = true;

				// Load initial data
				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
				this.allUOMsObj = { ...this.$store.getters.uoms };

				this.companyOptions = DropDownItemsUtil.retrieveAssetOwners(this.allCompaniesObj);
				this.uomOptions = DropDownItemsUtil.retrieveUOMs(this.allUOMsObj);

				this.filterTableContent();

			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}			
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseSaveAssetType', (data) => {
			let assetTypeObj = data.assetType;
			if (!_.isEmpty(assetTypeObj)) {
				this.updateTable(assetTypeObj);
			}
		});
	},
	methods: {
		updateTable(assetTypeObj) {
			if (!_.isEmpty(assetTypeObj)) {
				this.allAssetTypesObj[assetTypeObj.id] = assetTypeObj;
				this.$store.dispatch('setAllAssetTypes', this.allAssetTypesObj);

				this.filterTableContent();
			}
		},
		filterTableContent() {
			let filteredObjs = { ...this.allAssetTypesObj };

			_.forEach(filteredObjs, (assetType, assetTypeId) => {
				if (
					this.filterBy.company.id !== null &&
					this.filterBy.company.id !== assetType.originId
				) {
					delete filteredObjs[assetTypeId];
				}

				if (this.filterBy.isActive !== assetType.isActive) {
					delete filteredObjs[assetTypeId];
				}
			});

			this.processAssetTypes(filteredObjs);
		},
		processAssetTypes(assetTypesObj){
			this.items = Object.values(assetTypesObj);

			_.forEach(this.items, item => {
				let uomCode = AssetTypeUtil.getUOMCode(item);
				item['uom'] = AssetTypeUtil.getUOMDisplay(uomCode, this.allUOMsObj);
				item['unitCost'] = AssetTypeUtil.getUnitCost(item);
				item['Other Inventory Details'] = this.getOtherInventoryDetails(item.inventoryDetails);
				item['status'] = item.isActive === 'true' ? 'Active' : 'Inactive';
				item['Is Rental?'] = item.isRental ? 'YES' : 'NO';
				item['Is Public?'] = item.isPublic ? 'YES' : 'NO';
				item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);

				// remove show details
				delete item._showDetails;
			});
			this.items = _.sortBy(this.items, ['name']);
			this.totalRows = this.items.length;

			this.$store.dispatch('updateAllAssetTypes', assetTypesObj);

			// refresh table
			if (this.$refs.assetTypesTable) {
				this.$refs.assetTypesTable.refresh();
			}
		},
		getOtherInventoryDetails(inventoryDetails) {
			let details = '';

			_.forEach(inventoryDetails, field => {
				if (field.id !== 'uom' && field.id !== 'unitCost') {
					details += field.fieldName + ' - ' + field.defaultValue + '; ';
				}
			});

			return details;
		},

		async onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				await this.retrieveData();
				this.prevFilter = { ...this.filterBy };
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };

				// reset validation
				this.$validator.reset();
				this.errors.clear();

				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
				this.filterTableContent();
			}
		},
		async retrieveData() {
			try {
				// Show loader
				this.isLoading = true;

				let filter = { ...this.filterBy };
				filter.withParentCompany = true;
				filter.companyId = this.loggedUserCompany.id;

				if (this.isSuperAdmin) {
					const { data } = await assetTypeApi.getAssetTypes(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allAssetTypesObj = _.assign(this.allAssetTypesObj, data.assetTypes);
				} else {
					const { data } = await assetTypeApi.getAssetTypes(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allAssetTypesObj = _.assign(this.allAssetTypesObj, data.assetTypes);
				}

				this.filterTableContent();
			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},

		updateSelAssetType(item) {
			this.selAssetType = item;
			this.$store.commit('SET_CURR_ASSET_TYPE', item);
			EventBus.$emit('onUpdateSelAssetType', item);
		},
		isAssetTypeOwner(assetType) {
			return (
				assetType.originId === this.loggedUserCompany.id ||
				this.loggedUser.type === 'Super Admin' ||
				!_.isEmpty(this.allCompaniesObj[assetType.originId])
			);
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
	beforeDestroy() {
		EventBus.$off('onCloseSaveAssetType');
	},
};
</script>
